<template>
    <main-navbar />
    <router-view />
</template>

<script>
import MainNavbar from '@/components/MainNavbar.vue';

export default {
    name: 'App',
    components: {
        MainNavbar
    }
}
</script>

<style lang="scss">
</style>
