import { defineStore } from 'pinia'
export const asidStore = defineStore({
    id: 'asidStore',
    state: () => ({
        _loginInfo: null,
        _loggedOnPerson: null,
        _webSocketBackoff: 100,
        _connected: true,
    }),
    getters: {
        loginInfo: (state) => state._loginInfo,
        loggedOnPerson: (state) => state._loggedOnPerson,
        webSocketBackoff: (state) => state._webSocketBackoff,
        connected: (state) => state._connected
    },
    actions: {
        setLoginInfo(loginInfo) {
            this._loginInfo = loginInfo;
        },
        setLoggedOnPerson(person) {
            this._loggedOnPerson = person;
        },
        performLogin() {
            if (process.env.NODE_ENV === 'development') {
                var ei = new window.EcIdentity();
                ei.ppk = window.EcPpk.fromPem(
                    "-----BEGIN RSA PRIVATE KEY-----MIIEpAIBAAKCAQEA3SxpZQdLak+xqLDza618Ln70bl9MwXLwGN4ksCClqDWVX3IGzJp0IIfRlKxzarDMDctRrMRW+/o2CkeA/kBYJb6dEj6LK1uIPNDiwQuxd1axSYwBdZ67MYDBIlCJ3hrfzc7gC8YR/HoaBItMRiGCLqI2KYJdsbBuHL2BXRs5VQnrdb2FfgVLwxz36w5AWtV5xRRMNwkRNeSJKqXXs88AUBzUFo8PG/uJ7l9c/EznpnBLD92wkQkw06Thf5yYgLrZyLhdDCRRq+Sh46dACE9FYepugF5SVBGfgpzEWbbBlXUfKOMcEKm0IwONJP6Zwm12KReHmu0ZcfeyJk2hjdE7rwIDAQABAoIBAQDQSCOUnZL6scQXnVQPAhgg3TOowRxSTMAoWwJnzsbSKmvZr1MR2R4lCGJoxSIHxijTkLYAogFcV9wOQBoM1WFtYh0e8kEy+obI5tmzfCSKwtw/65XwQyP70UmP3BKJU/uRIBHDZKrJfd8Ein2wyZuD+QRSgM9lJ0X60MeDe8C4/9ar235ReHgR/KPZCpw/hAfLhLqJ5kiZ51sHyhbIh2j10K4LurokVb0Gzcol7Y9xToLNFscwO5+z7xgAuvaCjDnXgp1Q/xRo2wYz6/HwW0hSAyXbMLGiB/dbyquTMsVUGopTe3Gj+K2LxzhzagJvsIwGVNvphaFDAc2rLha+MUr5AoGBAPr9WsexP4EHm13xJVRze5dr4lXWvgxcqR7lQiw28TAuDFnWwX1XmiHpRhQIkI3mXTzIsvGVIsb3YBRho5nvKHqzdicGlUeTCkCV/sqVwyiUS8nJgk65ZNjU1H0sILAQ3l5AwhUTV3n8JFUW7f+akPRWKj21FJk/8WT2x62PW5g9AoGBAOGWr5gck4MevT7pwGpYe0hFG8mCVxzBtHto65m5PlJbhgK+oMSL1w7HQNxkiFeD3N3F+ecPw/PWBuOTLG2dqPfJj34T4DnT90qKH1Cuypx7AtJMjZbXbovz9A7shRBQxvWcV60eNHeuTdLSOoFrIhUHBp15Pt17Aw8j85FLznZbAoGAJmx85k9TSnyQo3wUW+5mAFNqzftXEutolKtCSySKHfceuu4upvKvTPu6V7zL7H1UzsZm5qcPMOiUE+qyshEz4DzzHJxcZB21lxefbwIoY2D4jUbFJSoqrys98H+97VZXxJQjYjiYzrr0EPa2rsfJ4y4Bv3qmUaPBX9SL0x08R30CgYEAs9sq9pDZ/zFT2sQNN/yBlhYbWGbFw1Sg67+0D6TEjm8QAkIv9WQ0u0CbctvoZDXqsxpz6wgDx/V9GpYtVtvbamtWL0Pzgsd/ovtKik5Blc7xae/E912dcpG2pOtoD6EHb60NriWl2UGsJIieQq7CxpcKbCTNvVGlF74nJFGXM38CgYB5wx9dK42Et34CHSJsp8VldExfSMjBLKtR++VZaWqiXf3/Zci53fFdIBjulE9QG4X1qhq7Ubnp/RTPgf+yufrujLFlXgmRzOC3ExAaYC0gJEtshKZlACMkMcC0B+zYRUwT83/ndH4d1e9/n9pxKEoz/i6zTqPl7WeEa8fkoT7w3Q==-----END RSA PRIVATE KEY-----"
                );
                console.log(ei.ppk);
                ei.displayName = "Test User";
                window.EcIdentityManager.default.addIdentity(ei);
                let ep = new window.EcPerson();
                ep.name = ei.displayName;
                this.setLoggedOnPerson(ep);
            } else {
                if (this._loginInfo.ssoLogin != null) {
                    window.location = this._loginInfo.ssoLogin + "?redirectUrl=" + encodeURIComponent(window.location);
                }
            }
        },
        performLogout() {
            if (process.env.NODE_ENV === 'development') {
                window.location.reload();
            } else {
                if (this._loginInfo.ssoLogin != null) {
                    window.location = this._loginInfo.ssoLogout + "?redirectUrl=" + encodeURIComponent(window.location);
                }
            }
        },
        webSocketBackoffIncrease() {
            this._webSocketBackoff = Math.min(this._webSocketBackoff * 2,120000);
        }
    }
})
