<template>
    <nav class="navbar is-fixed-top"
         role="navigation"
         aria-label="main navigation">
        <div class="navbar-brand">
            <a class="navbar-item"
               href="https://www.boeing.com/">
                <img src="@/assets/logo.png">
            </a>

            <a @click="showMobileMenu = !showMobileMenu"
               role="button"
               class="navbar-burger"
               :class="{ 'is-active': showMobileMenu }"
               aria-label="menu"
               aria-expanded="false"
               data-target="mainNavbar">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>

        <div id="mainNavbar"
             class="navbar-menu"
             :class="{ 'is-active': showMobileMenu }">
            <div class="navbar-start">
                <router-link class="navbar-item"
                             :to="{ path: '/', query: queryParams }">
                    Home
                </router-link>

                <!-- TODO: v-if user is admin -->
                <div v-if="store.loggedOnPerson"
                     class="navbar-item has-dropdown is-hoverable">
                    <a class="navbar-link">
                        Regulatory docs
                    </a>

                    <div class="navbar-dropdown">
                        <router-link :to="{ name: 'Standards Status' }"
                                     class="navbar-item">
                            FAA Standards API
                        </router-link>
                    </div>
                </div>
                <router-link v-if="store.loggedOnPerson" class="navbar-item"
                             :to="{ path: '/import', query: queryParams }">
                    Import MTA
                </router-link>
                <a class="navbar-item"
                   href="https://form.asana.com/?k=_HYNOPcttTEFTnUVdURKVw&d=66307021113275"
                   target="_blank">
                    Feedback
                </a>
                <div class="navbar-item">
                    <em><small class="is-ghost">TIDES Version {{ tidesVersion }}</small></em>
                </div>
            </div>


            <div class="navbar-end">
                <div v-if="lastLogin" class="navbar-item">
                    <div>
                        <div class="login-lbl">Last Login</div>
                        <div class="login-val">{{ lastLogin }}</div>
                    </div>
                </div>
                <div v-if="store.loggedOnPerson"
                     class="navbar-item">
                    <div>{{ store.loggedOnPerson.name }}</div>
                </div>
                <div v-if="store.loggedOnPerson == null && store.loginInfo && store.loginInfo.ssoLogin"
                     class="navbar-item">
                    <a :href="store.loginInfo.ssoLogin">Login</a>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>

// TODO: Get username from SSO, if possible
import { asidStore } from "@/stores/store";
export default {
    name: 'MainNavbar',
    setup() {
        const store = asidStore();
        return { store: store };
    },
    data: () => {
        return {
            showMobileMenu: false,
            username: "Jon Smith",
            disconnectTimeout: null,
            lastLogin: null,
        };
    },
    created: function () {
        window.EcRepository.caching = true;
        var r = new window.EcRepository();
        let initSuccess = () => {
            console.log('initialized');
            this.openWebSocket(r);
        };
        let initError = console.error;
        let initAfter = async (loginInfo) => {
            console.log('login info', loginInfo);
            window.repo.fetchServerAdminKeys(async () => {
                this.store.setLoginInfo(loginInfo);
                if (window.EcIdentityManager.default.ids.length > 0) {
                    let p = (await window.EcPerson.getByPk(r, window.EcIdentityManager.default.ids[0].ppk.toPk()));
                    if (p != null)
                        window.EcIdentityManager.default.ids[0].displayName = p.getName();
                }
                if (loginInfo.ssoAdditionalPublicKeys != null)
                    for (let i = 0; i < loginInfo.ssoAdditionalPublicKeys.length; i++) {
                        let ppk = window.EcPpkFacade.fromPem(loginInfo.ssoAdditionalPublicKeys[i]);
                        let ident = new window.EcIdentity();
                        let p = (await window.EcPerson.getByPk(r, ppk.toPk()));
                        if (p != null)
                            ident.displayName = p.getName();
                        ident.ppk = ppk;
                        window.EcIdentityManager.default.addIdentity(ident);
                    }

                // Auto select the last identity in the list
                if (window.EcIdentityManager.default.ids.length > 0)
                    window.EcIdentityManager.default.ids.unshift(window.EcIdentityManager.default.ids.splice(window.EcIdentityManager.default.ids.length - 1, 1)[0]);

                if (loginInfo.ssoPublicKey != null && loginInfo.ssoLogin == null) //In some situations (JWT cookie refreshing) there is a need for a keepalive.
                    if (window.initInterval == null)
                        window.initInterval = setInterval(() => r.init(r.selectedServer), 10000);

                if (this.linkedPerson == null && window.EcIdentityManager.default.ids && window.EcIdentityManager.default.ids.length > 0) {
                    this.findLinkedPersonForIdentity();
                }
            }, ()=>{});
        }
        let initDetect = () => {
            console.log('Could not init. Detecting endpoint.');
            r.autoDetectRepositoryAsync(
                () => r.init(r.selectedServer, initSuccess, initError, initAfter),
                initError
            );
        }
        r.init(window.location.origin + "/api/", initSuccess, initDetect, initAfter);
        window.repo = r;
        setTimeout(() => {
            // If crypto workers haven't loaded forgeAsync.js at repo init, need to try again to load the identity.
            if (this.linkedPerson == null && window.EcIdentityManager.default.ids && window.EcIdentityManager.default.ids.length > 0) {
                this.findLinkedPersonForIdentity();
            }
        }, 1000);
    },
    computed: {
        tidesVersion: function() {
            return process.env.VUE_APP_VERSION;
        },
    },
    methods: {
        openWebSocket: function (r) {
            var connection;
            // Instead of /ws/custom, will be /ws in next release.
            connection = new WebSocket(r.selectedServer.replace(/http/, "ws").replace(/api\//, "ws/custom"));

            connection.onopen = () => {
                console.log("WebSocket open.");
                this.store._connected = true;
                if (this.disconnectTimeout) {
                    clearTimeout(this.disconnectTimeout);
                    this.disconnectTimeout = null;
                }
                this.store._webSocketBackoff = 100; //reset the backoff on successful connection
            };

            connection.onerror = (error) => {
                console.error(error);
                if (!this.disconnectTimeout)
                    this.disconnectTimeout = setTimeout(() => {
                        this.store._connected = false;
                    }, 3000);
            };

            // Re-establish connection on close.
            connection.onclose = () => {
                if (!this.disconnectTimeout)
                    this.disconnectTimeout = setTimeout(() => {
                        this.store._connected = false;
                    }, 3000);
                this.store.webSocketBackoffIncrease();
                setTimeout(() => {
                    this.openWebSocket(r);
                }, this.store.webSocketBackoff);
            };
        },
        feedback: function () {
            alert("TO DO: Attach email or url to the feedback button")
        },
        thing: function () {
            console.log(this.store.loggedOnPerson);
        },
        findLinkedPersonForIdentity: function () {
            // let identFingerprint = window.EcIdentityManager.default.ids[0].ppk.toPk().fingerprint();
            // let paramObj = {};
            // paramObj.size = 10000;
            window.EcPerson.getByPk(window.repo, window.EcIdentityManager.default.ids[0].ppk.toPk(), this.findLinkedPersonPersonSearchSuccess, console.error);
            // window.repo.searchWithParams("@type:Person AND @id:\"" + identFingerprint + "\"", paramObj, null,
            //   this.findLinkedPersonPersonSearchSuccess, console.error);
        },
        findLinkedPersonPersonSearchSuccess(ecRemoteLda) {
            if (!window.EcArray.isArray(ecRemoteLda))
                ecRemoteLda = [ecRemoteLda];
            // let matchingPersonRecordFound = false;
            for (let ecrld of ecRemoteLda) {
                let ep = new window.EcPerson();
                ep.copyFrom(ecrld);
                if (ep.getGuid().equals(window.EcIdentityManager.default.ids[0].ppk.toPk().fingerprint())) {
                    // matchingPersonRecordFound = true;
                    this.linkedPerson = ep;
                    this.store.setLoggedOnPerson(ep);
                }
            }
            this.getLastLogin();
            // if (matchingPersonRecordFound) this.addGroupIdentities();
        },
        getLastLogin: async function() {
            if (window.EcIdentityManager.default.ids.length != 0){
                let person = await window.EcPerson.getByPk(window.repo, window.EcIdentityManager.default.ids[0].ppk.toPk());
                if (person.lastLogin)
                    this.lastLogin = this.formatDateString(new Date(person.lastLogin));
            }
        },
        formatDateString(date) {
            function formatTime(date) {
                let hours = date.getHours();
                const minutes = padZero(date.getMinutes());
                const ampm = hours >= 12 ? 'PM' : 'AM';

                hours = hours % 12;
                hours = hours ? hours : 12; // The hour '0' should be '12' in AM/PM

                return `${hours}:${minutes} ${ampm}`;
            }

            function getDayOfWeek(date) {
                const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                return daysOfWeek[date.getDay()];
            }

            function padZero(num) {
                return num < 10 ? `0${num}` : num;
            }
            const now = new Date();
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
            const yesterday = new Date(now);
            yesterday.setDate(now.getDate() - 1);

            if (date >= today) {
                // Today
                return `Today at ${formatTime(date)}`;
            } else if (date >= yesterday) {
                // Yesterday
                return `Yesterday at ${formatTime(date)}`;
            } else if (date >= today - 6 * 24 * 60 * 60 * 1000) {
                // Within the same week
                const dayOfWeek = getDayOfWeek(date);
                return `${dayOfWeek} at ${formatTime(date)}`;
            } else {
                // Default format: mm/dd/yy
                return `${padZero(date.getMonth() + 1)}/${padZero(date.getDate())}/${date.getFullYear().toString().slice(2)}`;
            }
        }
    },
    watch: {
        "store.loggedOnPerson": function (newVal) {
            if (newVal)
                this.getLastLogin();
        }
    }

}
</script>

<style lang="scss" scoped>
.navbar {
    background-color: rgba(#fff, 0.8);
    backdrop-filter: blur(10px);

    .navbar-item:last-child {
        padding-right: 0;
    }
}

.login-lbl {
    font-size: 12px;
    opacity: 0.8;
    font-weight: bold;
    line-height: 1;
}

.login-val {
    padding: 1px 7.5px;
    border-radius: 5px;
    background-color: rgb(0,0,0,0.75);
    font-size: 12px;
    font-weight: bold;
    line-height: 1;
    color: white;
}
</style>