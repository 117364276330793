
<template>
    <Transition name="fade">
        <div v-if="showModal"
             class="modal"
             :class="{ 'is-active': showModal }">
            <div @click="close"
                 class="modal-background" />
            <div class="modal-content">
                <h3 class="subtitle is-3 has-text-light has-text-centered">{{ videoTitle }}</h3>
                <video :key="videoSource"
                       width="100%"
                       controls
                       autoplay>
                    <source :src="videoSource"
                            :type="videoType">
                    Your browser does not support video.
                </video>
                <!-- </figure> -->
            </div>
            <button @click="close"
                    class="modal-close is-large"
                    aria-label="close"></button>
        </div>
    </Transition>
</template>

<script>
export default {
    name: 'VideoModal',
    data: () => {
        return {
            showModal: false,
            videoTitle: '',
            videoSource: '',
            videoType: 'video/mp4',
        };
    },
    methods: {
        show(video) {
            this.videoTitle = video.title;
            this.videoSource = video.url;
            this.videoType = video.type;
            this.showModal = true;
        },
        close() {
            this.showModal = false;
        }
    },
}
</script>

<style lang="scss" scoped>
.modal-content {
    display: grid;
    place-items: center;
    max-height: 90vh;
    width: 90vw;

    video {
        /* override other styles to make responsive */
        width: 100% !important;
        height: auto !important;
        max-height: 90vh !important;
    }
}
</style>