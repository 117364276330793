<!--
*   File Name: 01ImportProductData.vue
*
*   Classification:  Unclassified
*
*   Prime Contract No.: OPM2615D0001
*
*   This work was generated under U.S. Government contract and the
*   Government has unlimited data rights therein.
*
*   Copyrights:      Copyright 2017
*                    Eduworks Corporation.
*                    All rights reserved.
*
*   Distribution Statement A: Approved for public release; distribution is unlimited
*
*   Organizations:   Eduworks Corporation.
*                    400 SW 4th St.
*                    Corvallis, OR 97224
*
-->

<!--
    Import Product Data
    Composes 01ImportProductData.vue page element
    Children Component(s):
    Parent Component(s): Tasks.vue
-->
<template>
    <div class="section">
        <h2 class="title is-size-2">
            Import Task Analysis
        </h2>
        <p>In this screen, Task Analyses are imported from Logistical Support Analysis systems to enable a Training Needs Analysis.</p>
        <hr>
        <!-- connect to data source -->
        <transition name="fade">
            <div v-if="!download"
                class="section">
                <div class="columns is-multiline">
                    <div class="column is-two-thirds">
                        <div class="field">
                            <div class="label">
                                <div class="control">
                                    <div class="select is-fullwidth is-primary is-medium">
                                        <select v-model="source"
                                                @change="chooseFile">
                                            <option disabled
                                                    value>
                                                Select your MTA
                                            </option>
                                            <option v-for="(dbSource, index) in dbSources"
                                                    :key="index"
                                                    :disabled="dbSource.disabled"
                                                    :value="dbSource.value">
                                                {{ dbSource.label }}
                                            </option>
                                        </select>
                                        <input type="file"
                                            ref="fileInput"
                                            style="display:none"
                                            @change="fileChange">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-one-third">
                        <a 
                        @click="importFile"
                        class="button is-medium is-fullwidth is-primary">Connect</a>
                    </div>
                </div>
                <div v-if="file && source == 'fileImport'"
                    class="column is-12">
                    <span class="tag is-info is-large">
                        {{ fileName }}
                        <button @click="clearImport"
                                class="delete" />
                    </span>
                </div>
            </div>
        </transition>
        <!-- processing -->
        <transition name="fade">
            <div v-if="download && !saveComplete"
                class="section">
                <div v-if="download"
                    class="columns is-multiline">
                    <div class="column is-full">
                        <h3 class="subtitle is-size-3">
                            Downloading Product Data
                        </h3>
                    </div>
                    <div class="column is-full">
                        <div class="section is-paddingless">
                            <div class="columns is-mobile is-vcentered"
                                v-for="detail in downloadDetails"
                                :key="detail.type">
                                <div class="column is-7">
                                    <h4 class="is-size-4">
                                        {{ detail.type }}
                                    </h4>
                                </div>
                                <div class="column">
                                    <progress class="progress is-info"
                                            :value="detail.value"
                                            min="0"
                                            :max="detail.maxValue" />
                                    <span v-if="detail.value != Number.MAX_VALUE">{{ detail.value + '/' + detail.maxValue }}</span>
                                    <span v-else>None found.</span>
                                </div>
                                <div class="column is-narrow">
                                    <span v-if="detail.value === detail.maxValue && detail.value !== 0"
                                        class="has-text-success icon is-pulled-right">
                                        <span class="fas fa-check-circle fa-2x" />
                                    </span>
                                    <span v-else
                                        class="icon has-text-info is-pulled-right">
                                        <i class="fas fa-spinner fa-pulse fa-2x" />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="column is-5 is-offset-7">
                        <div class="columns is-vcentered">
                            <div class="column">
                                <a v-if="importComplete"
                                @click="saveThing"
                                class="button is-medium is-primary is-fullwidth">
                                    <span class="icon has-text-white">
                                        <i class="fas fa-cloud-upload-alt" />
                                    </span>
                                    <span>
                                        Import
                                    </span>
                                </a>
                                <a v-else
                                @click="clearImport"
                                class="button is-medium is-fullwidth is-warning">
                                    <span class="icon has-text-black">
                                        <i class="fas fa-times-circle" />
                                    </span>
                                    <span>
                                        Cancel
                                    </span>
                                </a>
                            </div>
                            <div class="column is-narrow">
                                <span v-if="saving"
                                    class="icon has-text-info is-pulled-right">
                                    <i class="fas fa-spinner fa-pulse fa-2x" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div v-if="saving" class="column is-5 is-offset-7">
                        <div class="columns is-vcentered">
                            <div class="column">
                                <progress class="progress is-info"
                                            :value="importingIndex"
                                            min="0"
                                            :max="importingTotal" />
                                <span>{{ (importingIndex + 1) + '/' + importingTotal }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <!-- import details -->
        <transition name="fade">
            <div class="section"
                v-if="saveComplete">
                <div class="columns is-multiline is-mobile">
                    <div class="column is-full">
                        <h3 class="subtitle is-size-2">
                            Generation Report
                        </h3>
                    </div>
                    <div class="column is-full">
                        <div class="content">
                            <ul>
                                <li>{{ newData }} new pieces of data were found.</li>
                                <li>{{ changedData }} changed pieces of data were found.</li>
                                <li>{{ numNewTasks }} new tasks were created.</li>
                                <li>{{ numChangedTasks }} changed tasks were updated.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="column is-full">
                        <div class="content"
                            v-if="false">
                            <h4 class="is-size-4 has-text-warning">
                                Warnings
                            </h4>
                            <ul>
                                <li v-for="(warning, index) in warnings"
                                    :key="index">
                                    {{ warning.field }} was not found for
                                    {{ warning.task }}, decision support will be disabled.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="column is-4 is-offset-8">
                        <a @click="clearImport"
                        class="button is-fullwidth is-medium is-primary">
                            <span class="icon has-text-white">
                                <i class="fas fa-redo" />
                            </span>
                            <span>
                                Import Again
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
var parseString = require("xml2js").parseString;
export default {
    name: "ImportProductData",
    setup() {
    },
    data: function () {
        return {
            importingIndex: 0,
            importingTotal: 0,
            error: null,
            download: false,
            file: null,
            source: "",
            importedData: null,
            toSave: [],
            tasks: [],
            product: null,
            localXbs: [], // TODO: S3000L
            saveComplete: false,
            dbSources: [
                {
                    value: "fileImport",
                    label: "Import a local GEIA 0007 file",
                    disabled: false
                },
                {
                    value: "fileImport",
                    label: "Import a local S3000L file",
                    disabled: false
                },
                {
                    value: "slicwave",
                    label: "Connect to Slicwave",
                    disabled: true
                },
                {
                    value: "windchill",
                    label: "Connect to Windchill",
                    disabled: true
                },
                {
                    value: "contenta",
                    label: "Connect to Contenta",
                    disabled: true
                },
                {
                    value: "other",
                    label: "Other CSDB...",
                    disabled: true
                }
            ],
            downloadDetails: {
                convertData: {
                    type: "Convert Product Data to Linked Data",
                    value: 0,
                    maxValue: 0
                },
                identifyData: {
                    type: "Identify New or Changed Data",
                    value: 0,
                    maxValue: 0
                },
                identifyTasks: {
                    type: "Identify New or Changed Tasks",
                    value: 0,
                    maxValue: 0
                },
                changedTasks: {
                    type: "Mark Changed Tasks",
                    value: 0,
                    maxValue: 0
                },
                newTasks: {
                    type: "Generate New Tasks",
                    value: 0,
                    maxValue: 0
                }
            },
            warnings: [],
            fieldsNeeded: {// TODO: S3000L
                task_code: "Task Code",
                task_criticality_code: "Criticality Code",
                hardness_critical_procedure_code: "Hardness Critical Procedure Code",
                hazardous_maintenance_procedure_code: "Hazardous Maintenance Procedure Code",
                task_frequency: "Task Frequency"
            },
            numNewTasks: null,
            numChangedTasks: null,
            newData: 0,
            changedData: 0,
            saving: false
        };
    },
    created: function () {

    },
    computed: {
        downloadEnabled: function () {
            if (this.source) {
                if (this.source === "fileImport") {
                    if (this.file) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            } else {
                return false;
            }
        },
        fileName: function () {
            return this.file.name;
        },
        importComplete: function () {
            for (let i in this.downloadDetails) {
                let value = this.downloadDetails[i].value;
                let max = this.downloadDetails[i].maxValue;
                if (max / value !== 1) {
                    return false;
                }
            }
            return true;
        },
        warningsReport: function () {
            return this.warnings.length > 0;
        }
    },
    methods: {
        clearImport: function () {
            this.saveComplete = false;
            this.download = false;
            this.file = null;
            this.source = "";
            if (this.$refs.fileInput) {
                this.$refs.fileInput.value = null;
            }
            this.importedData = null;
            this.toSave.splice(0, this.toSave.length);
            this.tasks.splice(0, this.tasks.length);
            this.numNewTasks = null;
            this.numChangedTasks = null;
            this.newData = 0;
            this.changedData = 0;
            let keys = window.EcObject.keys(this.downloadDetails);
            for (let key in keys) {
                this.downloadDetails[keys[key]].value = 0;
                this.downloadDetails[keys[key]].maxValue = 0;
            }
            this.importingIndex = 0;
            this.importingTotal = 0;
        },
        chooseFile: function () {
            console.log("choosefile");
            console.log(this.$refs.fileInput);
            if (this.source === "fileImport") {
                this.source = '';
                this.$refs.fileInput.click();
            }
        },
        fileChange: function (e) {
            console.log(e);
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.file = null;
            } else {
                this.source = 'fileImport';
                this.file = files[0];
            }
        },
        importS3000L: function (data) {
            var me = this;
            me.toSave.splice(0, me.toSave.length);

            var namespace = "http://www.asd-europe.org/s-series/s3000l";

            var lookupTable = {};
            var revLookupTable = {};
            var candidatesToSave = [];

            function shatter(data, prevName, prevId) {
                // importParseText indicator ++
                me.downloadDetails.convertData.value++;
                if (window.EcArray.isArray(data)) {
                    // importParseTextMax += data.length
                    me.downloadDetails.convertData.maxValue += data.length;
                    for (var i = 0; i < data.length; i++) {
                        let result = shatter(data[i], prevName, prevId);
                        if (result === true) {
                            data.splice(i, 1);
                        } else if (result === false || result == null)
                            ;
                        else {
                            data[i] = result;
                        }
                    }
                    if (data.length === 0) { return true; }
                    return data;
                } else if (window.EcObject.isObject(data)) {
                    if (data["$"] != null) {
                        if (data["$"].uid != null) {
                            if (revLookupTable[data["$"].uid] == null) {
                                revLookupTable[data["$"].uid] = {};
                            }
                            if (revLookupTable[data["$"].uid]["reverse" + prevName] == null) {
                                revLookupTable[data["$"].uid]["reverse" + prevName] = [];
                            }
                            if (prevId !== undefined) {
                                revLookupTable[data["$"].uid]["reverse" + prevName].push(window.EcRemoteLinkedData.veryShortId(window.repo.selectedServer, prevId));
                            }
                            prevId = data["$"].uid;
                        }
                    }

                    // We're in something that can be called a fragment. Recurse first, then turn into an object and save.

                    // importParseTextMax += EcObject.keys(data).length
                    me.downloadDetails.convertData.maxValue += window.EcObject.keys(data).length;
                    for (var prop in data) {
                        if (prop === "$") {
                            me.downloadDetails.convertData.value++;
                            continue;
                        }
                        let result = shatter(data[prop], prop, prevId);
                        if (result === true) {
                            delete data[prop];
                        } else if (result === false || result == null)
                            ;
                        else {
                            data[prop] = result;
                        }
                    }

                    if (data["$"] != null) {
                        if (data["$"].uidRef != null) {
                            if (revLookupTable[data["$"].uidRef] == null) {
                                revLookupTable[data["$"].uidRef] = {};
                            }
                            if (revLookupTable[data["$"].uidRef]["reverse" + prevName] == null) {
                                revLookupTable[data["$"].uidRef]["reverse" + prevName] = [];
                            }
                            if (prevId !== undefined) {
                                revLookupTable[data["$"].uidRef]["reverse" + prevName].push(window.EcRemoteLinkedData.veryShortId(window.repo.selectedServer, prevId));
                            }
                            return window.EcRemoteLinkedData.veryShortId(window.repo.selectedServer, data["$"].uidRef);
                        }
                    }
                    if (data["$"] != null) {
                        if (data["$"].uid != null) {
                            var d;
                            if (lookupTable[prevName] != null) { d = new window.EcRemoteLinkedData(namespace, lookupTable[prevName]); } else { d = new window.EcRemoteLinkedData(namespace, prevName); }
                            d.assignId(window.repo.selectedServer, data["$"].uid);
                            me.downloadDetails.convertData.maxValue += window.EcObject.keys(data).length;
                            for (var param in data) {
                                me.downloadDetails.convertData.value++;
                                if (param === "$") {
                                    continue;
                                }
                                d[param] = data[param];
                            }
                            // console.log(d.toJson());
                            candidatesToSave.push(d);
                            return d.shortId();
                        }
                    }
                }
                return data;
            }

            me.downloadDetails.convertData.maxValue++;
            window.EcRemote.getExpectingObject("js/S3000LSchema.json", null, function (success) {
                var g = success["@graph"];
                for (let i = 0; i < g.length; i++) {
                    lookupTable[g[i]["meta:s3000lShortName"]] = g[i]["ceasn:codedNotation"];
                }
                console.log(lookupTable);
                shatter(data);
                var idArray = [];
                var toCheckData = {};
                for (let i = 0; i < candidatesToSave.length; i++) {
                    var thing = candidatesToSave[i];
                    if (revLookupTable[thing.getGuid()] != null) {
                        for (var param in revLookupTable[thing.getGuid()]) {
                            thing[param] = revLookupTable[thing.getGuid()][param];
                        }
                    }
                    idArray.push(candidatesToSave[i].shortId());
                    toCheckData[candidatesToSave[i].shortId()] = candidatesToSave[i];
                    me.downloadDetails["identifyData"].maxValue++;
                }
                window.EcRepository.cache = {};
                me.precache([...idArray],toCheckData,idArray);
            }, console.error);
        },
        precache: function(idArrayRemaining, toCheckData, idArray)
        {
            let me = this;
            window.repo.precache(idArrayRemaining.splice(0,1000), function () {
                if (idArrayRemaining.length > 0)
                    me.precache(idArrayRemaining,toCheckData,idArray);
                else
                    me.checkForChanges(toCheckData, idArray);
            });
        },

        importFile: function () { // TODO: S3000L
            this.download = !this.download;
            var fr = new FileReader();
            var me = this;
            fr.onload = function (progress) {
                if (progress.target.result.indexOf("geia:GEIA-STD-0007") !== -1) {
                    parseString(progress.target.result, function (err, result) {
                        if (err != null) me.error = err;
                        me.importedData = JSON.parse(
                            JSON.stringify(
                                result["geia:GEIA-STD-0007"]["geia:full_file"]
                            ).replace(/geia:/g, "")
                        );
                        me.toSave.splice(0, me.toSave.length);
                        me.collapse(me.importedData);
                        me.upload(me.importedData[0]);
                    });
                } else if (progress.target.result.indexOf("http://www.asd-europe.org/s-series/s3000l") !== -1) {
                    parseString(progress.target.result, function (err, result) {
                        if (err != null) me.error = err;
                        me.importS3000L(result);
                    });
                }
            };
            fr.readAsText(this.file);
        },
        collapse: function (f) { // TODO: S3000L
            if (this.source !== "") {
                if (window.EcArray.isArray(f)) {
                    if (f.length === 1) {
                        return this.collapse(f[0]);
                    }
                    if (f.length === 0) return null;
                    for (let i = 0; i < f.length; i++) {
                        f[i] = this.collapse(f[i]);
                    }
                    return f;
                } else if (window.EcObject.isObject(f)) {
                    var keys = window.EcObject.keys(f);
                    for (let i = 0; i < keys.length; i++) {
                        f[keys[i]] = this.collapse(f[keys[i]]);
                    }
                    return f;
                } else {
                    return f;
                }
            }
        },
        // When we get a new or updated piece of data, we need to be able to save it.
        saveThing: async function () {
            this.saving = true;

            let errors = [];

            this.importingIndex = 0;
            this.importingTotal = this.toSave.length;

            for (let v of this.toSave) {
                var vSimple = new window.EcRemoteLinkedData();
                vSimple.copyFrom(v);
                vSimple.addOwner(window.EcIdentityManager.default.ids[0].ppk.toPk());
                // var encrypted = window.EcEncryptedValue.toEncryptedValue(vSimple);
                // delete encrypted.name;
                try {
                    await window.repo.saveTo(vSimple);
                } catch (e) {
                    errors.push(e);
                }
                
                this.importingIndex++;
            }

            this.saveComplete = true;
            this.saving = false;
            
            if (errors.length > 0) {
                console.error(errors);
                window.alert(errors.length + ' failed to save')
            }
        },
        // Determine which tasks are new and which were already in the system
        identifyTasks: function (tasks) {
            if (this.source !== "") {
                var me = this;
                var found = {};
                window.EcCompetency.search(window.repo,
                    "dcterms\\:type:Task",
                    function (results) {
                        for (let j = 0; j < results.length; j++) {
                            var each = results[j];
                            for (let i = 0; i < tasks.length; i++) {
                                if (
                                    window.EcRemoteLinkedData.trimVersionFromUrl(
                                        each.source
                                    ) === tasks[i].shortId()
                                ) {
                                    // task has already been saved.
                                    found[tasks[i].id] = true;
                                    // See if any of the underlying product data has changed
                                    for (let k = 0; k < me.toSave.length; k++) {
                                        if (
                                            me.toSave[k].shortId() ===
                                            tasks[i].shortId()
                                        ) {
                                            // If it's in toSave array, there's new or changed product data
                                            me.downloadDetails["changedTasks"].maxValue++;
                                            me.downloadDetails["changedTasks"].value++;
                                            me.checkFields(tasks[i]);
                                            // Invalidate signatures
                                            me.invalidateSignatures(each);
                                        }
                                    }
                                    // Check for changes upstream and invalidate signatures
                                    window.EcRepository.get(each.source, function (sourceData) { // TODO: S3000L
                                        if (
                                            sourceData != null &&
                                            sourceData.isAny(new window.EcEncryptedValue().getTypes())
                                        ) {
                                            var encrypted = new window.EcEncryptedValue();
                                            encrypted.copyFrom(sourceData);
                                            sourceData = encrypted.decryptIntoObject();
                                        }
                                        for (let k = 0; k < me.localXbs.length; k++) {
                                            if (me.localXbs[k].logistics_support_analysis_control_number === sourceData.logistics_support_analysis_control_number) {
                                                me.invalidateSignatures(each);
                                            }
                                        }
                                    }, function () { });
                                    me.downloadDetails["identifyTasks"].value++;
                                }
                            }
                        }
                        for (let i = 0; i < tasks.length; i++) {
                            if (found[tasks[i].id] === null || found[tasks[i].id] === undefined) 
                            {
                                // Training task hasn't been saved previously. Create new.
                                me.downloadDetails["newTasks"].maxValue++;
                                me.downloadDetails["identifyTasks"].value++;
                                me.downloadDetails["newTasks"].value++;
                                me.checkFields(tasks[i]);
                                let source = tasks[i];
                                let task = new window.EcCompetency();
                                task.source = tasks[i].shortId();
                                if (source.task_identification != null)
                                    task.name = source.task_identification;
                                else
                                    task.name = source.name[0].descr[0];
                                task["dcterms:type"] = "Task";
                                task["ceasn:codedNotation"] = source.task_code;
                                task.assignId(window.repo.selectedServer,source.getGuid()+"_Task");
                                task.addOwner(window.EcIdentityManager.default.ids[0].ppk.toPk());
                                me.toSave.push(task);
                            }
                        }
                        if (me.downloadDetails["changedTasks"].maxValue === 0) {
                            // No changed tasks. Update counter.
                            me.downloadDetails["changedTasks"].maxValue = me.downloadDetails["changedTasks"].value = Number.MAX_VALUE;
                            me.numChangedTasks = 0;
                        } else {
                            me.numChangedTasks = me.downloadDetails["changedTasks"].value;
                        }
                        if (me.downloadDetails["newTasks"].maxValue === 0) {
                            // No new tasks. Update counter.
                            me.downloadDetails["newTasks"].maxValue = me.downloadDetails["newTasks"].value = Number.MAX_VALUE;
                            me.numNewTasks = 0;
                        } else {
                            me.numNewTasks = me.downloadDetails["newTasks"].value;
                        }
                    },
                    console.error,{size:10000}
                );
            }
        },
        // If the task has changed, remove signatures
        invalidateSignatures: function (task) {
            var keys = window.EcObject.keys(task);
            for (var key in keys) {
                if (keys[key].indexOf("Signature") !== -1) {
                    task[keys[key]].splice(0, task[keys[key]].length);
                }
            }
            window.EcEncryptedValue.encryptOnSave(task.id, true);
            // window.repo.saveTo(task);
        },
        checkForChanges: function (data, ids) {
            if (this.source !== "") {
                var me = this;
                var tasks = [];

                for (let j = 0; j < ids.length; j++) {
                    let cached = window.EcRepository.cache[ids[j]];
                    if (
                        cached != null &&
                        cached.isAny(new window.EcEncryptedValue().getTypes())
                    ) {
                        var encrypted = new window.EcEncryptedValue();
                        encrypted.copyFrom(cached);
                        cached = encrypted.decryptIntoObject();
                    }
                    let localData = data[ids[j]];
                    // TODO: S3000L
                    if (localData.type === "XA_end_item_acronym_code_data") {
                        me.product = localData;
                    }
                    if (new window.s3000l.Product().isA(localData.getFullType())) {
                        me.product = localData;
                    }
                    if (localData.type === "CA_task_requirement_data") {
                        tasks.push(localData);
                        me.downloadDetails["identifyTasks"].maxValue++;
                    }
                    if (new window.s3000l.TaskRevision().isA(localData.getFullType())) {
                        tasks.push(localData);
                        me.downloadDetails["identifyTasks"].maxValue++;
                    }
                    // TODO: S3000L
                    if (cached) {
                        let same = true;
                        for (let key in cached) {                            
                            // Skip id - version is different.
                            if (key === "id") {
                                continue;
                            } else if (key === "owner") {
                                continue;
                            } else if (key === "signature") {
                                continue;
                            } else {
                                if (JSON.stringify(cached[key]) === JSON.stringify(localData[key]) || (cached[key] == null && localData[key] == null)) {
                                    continue;
                                } else {
                                    same = false;
                                    break;
                                }
                            }
                        }
                        for (let key in localData) {
                            // Skip id - version is different.
                            if (key === "id") {
                                continue;
                            } else if (key === "owner") {
                                continue;
                            } else if (key === "signature") {
                                continue;
                            } else {
                                if (JSON.stringify(cached[key]) === JSON.stringify(localData[key]) || (cached[key] == null && localData[key] == null)) {
                                    continue;
                                } else {
                                    same = false;
                                    break;
                                }
                            }
                        }
                        if (same === false) {
                            // Changed data
                            me.toSave.push(localData);
                            this.changedData++;
                            if (localData.type === "XB_logistics_support_analysis_control_number_indentured_item_data") {
                                me.localXbs.push(localData);
                            }
                            if (new window.s3000l.TaskRevision().isA(localData.getFullType())) {
                                me.localXbs.push(localData);
                            }
                        }
                    } else {
                        // New data
                        me.toSave.push(localData);
                        this.newData++;
                    }
                    me.downloadDetails["identifyData"].value++;
                }
                this.identifyTasks(tasks);
            }
        },
        upload: function (data) { // TODO: S3000L
            // Function to test the uniqueness of the primary keys and achieve a minimum set of unique identifying keys.
            function testUniqueKeys(type) {
                var testFields = JSON.parse(JSON.stringify(pks[type]));
                var sampleThisOneToo = testFields.pop();
                var targetUniqueCount = data[type].length;
                var sampleList = data[type];
                var samples = {};
                if (testFields.length === 0) {
                    console.log(testFields);
                    return true;
                }
                for (let i = 0; i < sampleList.length; i++) {
                    var sample = data[type][i];
                    var id = type + "_";
                    for (var j = 0; j < testFields.length; j++) {
                        if (j > 0) id += "_";
                        /*
                            * if (sample[testFields[j]] === undefined)
                            *   return false;
                            */
                        if (
                            sample[testFields[j]] !== undefined &&
                            sample[testFields[j]].length > 70
                        ) {
                            return false;
                        }
                        if (
                            sample[sampleThisOneToo] !== undefined &&
                            sample[sampleThisOneToo].length > 70
                        ) {
                            return false;
                        }
                        id += sample[testFields[j]];
                    }
                    samples[id] = true;
                }
                if (targetUniqueCount === 1) return true;
                if (window.EcObject.keys(samples).length < targetUniqueCount) {
                    return true; // We have reached distinction.
                }
                return false;
            }

            if (this.source !== "") {
                window.EcRepository.caching = true;

                var pks = {};

                var namespace = "http://www.geia_STD_0007.com/2006/schema";

                let types = window.EcObject.keys(data);
                // Identify primary keys of each type of new data.
                for (let i = 0; i < types.length; i++) {
                    let type = types[i];
                    let instances = data[type];
                    if (!window.EcArray.isArray(instances)) {
                        data[type] = [data[type]];
                        instances = data[type];
                    }
                    for (let j = 0; j < instances.length; j++) {
                        let fields = window.EcObject.keys(instances[j]);
                        if (pks[type] == null) pks[type] = fields;
                        else {
                            for (let k = 0; k < fields.length; k++) {
                                if (pks[type].indexOf(fields[k]) === -1) {
                                    pks[type].push(fields[k]);
                                }
                            }
                        }
                    }
                    for (let j = 0; j < instances.length; j++) {
                        let fields = window.EcObject.keys(instances[j]);
                        for (let k = 0; k < pks[type].length; k++) {
                            if (
                                instances[j][fields[k]] !== null &&
                                instances[j][fields[k]] !== undefined &&
                                instances[j][fields[k]].length > 70
                            ) {
                                pks[type].splice(k, 1);
                            }
                        }
                    }
                }

                // Running testUniqueKeys against all the types.
                for (let i = 0; i < types.length; i++) {
                    let type = types[i];
                    console.log(type);
                    console.log(pks[type]);
                    while (!testUniqueKeys(type)) pks[type].pop();
                    console.log(type + " minimally described by " + pks[type]);
                }

                // Convert and save data.
                types = window.EcObject.keys(data);
                var duplicateFinder = {};
                var idArray = [];
                var toCheckData = {};
                for (let i = 0; i < types.length; i++) {
                    let type = types[i];
                    let instances = data[type];
                    this.downloadDetails["convertData"].maxValue++;
                    for (let j = 0; j < instances.length; j++) {
                        this.downloadDetails["identifyData"].maxValue++;
                        var rld = new window.EcRemoteLinkedData();
                        rld.copyFrom(instances[j]);
                        rld.context = namespace;
                        rld.type = type;
                        var id = type.substring(0, 2) + "_";
                        for (let k = 0; k < pks[type].length; k++) {
                            if (k > 0) id += ".";
                            if (instances[j][pks[type][k]] === undefined) id += "";
                            else id += instances[j][pks[type][k]];
                        }
                        id = id.replace(/ /g, "_").replace(/\//g, "_");
                        rld.assignId(
                            window.repo.selectedServer,
                            id
                        );
                        if (
                            duplicateFinder[type + id] !== null &&
                            duplicateFinder[type + id] !== undefined &&
                            duplicateFinder[type + id].toJson() !== rld.toJson()
                        ) {
                            console.log(duplicateFinder[type + id].toJson());
                            console.log(rld.toJson());
                            console.log(type + id + " duplicated");
                        }
                        duplicateFinder[type + id] = rld;
                        idArray.push(rld.shortId());
                        toCheckData[rld.shortId()] = rld;
                    }

                    console.log(window.repo.selectedServer + "data?q=@type:" + type);
                    this.downloadDetails["convertData"].value++;
                }
                var me2 = this;
                window.EcRepository.cache = {};
                me2.precache([...idArray],toCheckData,idArray);
            }
        },
        // Check that required fields are present
        checkFields: function (data) { // TODO: S3000L
            if (this.source !== "") {
                var keys = window.EcObject.keys(this.fieldsNeeded);
                for (var keyIndex = 0; keyIndex < keys.length; keyIndex++) {
                    if (data[keys[keyIndex]] == null || data[keys[keyIndex]] === undefined) {
                        this.warnings.push({ field: this.fieldsNeeded[keys[keyIndex]], task: data["task_identification"] });
                    }
                }
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/styles.scss';

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
    {
    opacity: 0;
}
</style>
