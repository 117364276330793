import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Import from '../views/01ImportProductData.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/standards-status',
    name: 'Standards Status',
    component: () => import('../views/StandardsApiStatus.vue')
  },
  {
    path: '/import',
    name: 'import',
    component: Import
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
