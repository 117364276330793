<template>
  <div id="home"
       class="screen">
    <section class="hero is-medium">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column">
              <div class="card">
                <div class="card-content content">
                  <p class="title is-4">Training Needs Analysis</p>
                  <p class="">
                    Perform training needs analysis, define strategy, and validate curriculum
                    planning.
                  </p>
                  <div class="buttons">
                    <a :href="store.loggedOnPerson == null ? 'access_denied.html' : asidUrl"
                       target="_blank"
                       tabindex="0"
                       class="button is-primary" v-if="store.loggedOnPerson == null" disabled="true">Launch app</a>
                    <a :href="store.loggedOnPerson == null ? 'access_denied.html' : asidUrl"
                       target="_blank"
                       tabindex="0"
                       class="button is-primary" v-else>Launch app</a>
                    <a :href="tidesDocumentationUrl"
                       target="_blank"
                       tabindex="0"
                       class="button is-secondary">View TIDES documentation</a>
                    <a :href="s6000tUrl"
                       target="_blank"
                       tabindex="0"
                       class="button is-secondary">View S6000T standard</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="card">
                <div class="card-content content">
                  <p class="title is-4">Competency Management</p>
                  <p class="">
                    Set up and configure hierarchical taxonomies, schemas, and mapping.</p>
                  <div class="buttons">
                    <a :href="store.loggedOnPerson == null ? 'access_denied.html' : cassUrl"
                       target="_blank"
                       tabindex="0"
                       class="button is-primary" v-if="store.loggedOnPerson == null" disabled="true">Launch app</a>
                    <a :href="store.loggedOnPerson == null ? 'access_denied.html' : cassUrl"
                       target="_blank"
                       tabindex="0"
                       class="button is-primary" v-else>Launch app</a>
                    <a :href="cassDocumentationUrl"
                       target="_blank"
                       tabindex="0"
                       class="button is-secondary">View Editor documentation</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div class="container">
        <h4 class="title is-4">Additional Resources</h4>

        <div class="columns is-multiline is-mobile">
          <div v-for="video in resources.videos"
               :key="video.title"
               class="column is-full-mobile is-half-tablet">
            <a v-if="video.type.startsWith('video')"
               @click="showVideo(video)"
               @keydown.enter="showVideo(video)"
               tabindex="0"
               class="box video-link">

              <article class="media">
                <div class="media-left">
                  <figure class="image is-64x64">
                    <img src="@/assets/icon-video-play.svg"
                         class="video-icon"
                         alt="Play video">
                  </figure>
                </div>
                <div class="media-content">
                  <div class="content">
                    <p>
                      <strong>{{ video.title }}</strong><br />
                      {{ video.description }}
                    </p>
                  </div>
                </div>
              </article>
            </a>
          </div>
        </div>

        <div class="columns is-multiline is-mobile">
          <div v-for="link in resources.links"
               :key="link.title"
               class="column is-full-mobile is-half-tablet">
            <a :href="link.url"
               tabindex="0"
               target="_blank">
              {{ link.title }}
            </a>
            <p>{{ link.description }}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
  <video-modal ref="videoModal" />
  <div v-if="!store.connected" class="notification is-danger fixed-bottom">
    <p>Attempting to reconnect...  <a @click="reload">Reload</a></p>
  </div>
  <div v-if="store.loginInfo != null && (store.loginInfo.ssoAdditionalPublicKeys == null || store.loginInfo.ssoAdditionalPublicKeys.length == 0)" class="notification is-danger fixed-bottom">
    <p>You are not authorized to access TIDES. Please contact <strong>DL TNA App</strong> <a href="mailto:DL-TNAApp@exchange.boeing.com">DL-TNAApp@exchange.boeing.com</a> for access.  <a @click="reload">Reload</a></p>
  </div>
</template>

<script>
import { asidStore } from '@/stores/store';
import VideoModal from '@/components/VideoModal.vue';

export default {
  name: 'HomeView',
  components: {
    VideoModal
  },
  setup() {
    const store = asidStore();
    return { store: store };
  },
  data: () => {
    return {
      linkedPerson: null,

      // TODO: Move this to an admin editable location such as CaSS or JSON
      resources: {
        videos: [
          { title: 'Creating a new framework', type: 'video/mp4', url: 'videos/Create_Framework_Add_Competency_and_Child.mp4', description: 'Video session on how to manually create a new framework with competencies and sub-competencies.' },
          { title: 'Adding properties to and moving competencies', type: 'video/mp4', url: `videos/Adding_properties_to_and_moving_competencies.mp4`, description: 'Video on how to edit existing competencies to add properties and move competencies around within the framework hierarchy.' },
          { title: 'Create a new taxonomy and add concepts', type: 'video/mp4', url: `videos/Create_a_Taxonomy_and_add_concepts.mp4`, description: 'Video on creating a taxonomy from scratch.' },
          { title: 'Adding properties to and moving concepts in a taxonomy', type: 'video/mp4', url: `videos/Adding_properties_to_and_moving_concepts_in_a_taxonomy.mp4`, description: 'Video about managing and editing concepts in a taxonomy.' },
        ],
        links: [
          { title: 'Privacy Notice', type: 'link', url: 'https://globalprivacy.web.boeing.com/privacy-notice.asp?ID=7895', description: '' },
        ]
      }
    };
  },
  mounted() {
  },
  computed: {
    cassUrl: function () {
      if (process.env.NODE_ENV === 'development') {
        return process.env.VUE_APP_CASS_URL;
      } else {
        return `editor`;
      }
    },
    asidUrl: function () {
      if (process.env.NODE_ENV === 'development') {
        return process.env.VUE_APP_ASID_URL;
      } else {
        return `asid`;
      }
    },
    asidDocumentationUrl: function () {
      if (process.env.NODE_ENV === 'development') {
        return process.env.VUE_APP_ASID_DOCUMENTATION_URL;
      } else {
        return `editor/docs`;
      }
    },
    s6000tUrl: function () {
      return `https://www.s6000t.org/docs/S6000T%20Issue%202.0.pdf`;
    },
    cassDocumentationUrl: function () {
      if (process.env.NODE_ENV === 'development') {
        return process.env.VUE_APP_CASS_DOCUMENTATION_URL;
      } else {
        return `editor/docs`;
      }
    },
    tidesDocumentationUrl: function () {
      return `TIDES%20Documentation%20v1.2.0.pdf`;
    },
  },
  methods: {
    showVideo(video) {
      this.$refs.videoModal.show(video)
    },
    reload() {
      window.location.reload();
    },
  }
}
</script>

<style lang="scss" scoped>
#home {
  .hero .hero-body {
    position: relative;
    background: url(@/assets/Media_Room_Header_1500x466.jpg) center no-repeat;
    background-size: cover;

    .card {
      height: 100%;

      .card-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;

        &> :last-child {
          margin-top: auto;
        }
      }
    }
  }

  .video-link {
    height: 100%;
    transition: all 200ms;

    .video-icon {
      opacity: 0.5;
    }

    &:hover .video-icon {
      opacity: 1;
    }
  }
}

.notification.fixed-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
}
</style>
